// leo dutra
// https://gist.github.com/leodutra/64d90b988535f9e15464165718a49064
import $$ from "./double-dollar";

const hasCoarsePointer = () => window.matchMedia("(pointer: coarse)").matches;
const hasMobileWidth = (maxWidth: number) => window.matchMedia(`(max-width: ${maxWidth}px)`).matches;
const hasMultipleTouchPoints = () => navigator.maxTouchPoints > 1;
const hasTouchEvents = () => "ontouchstart" in document.documentElement;
const mobileMaxWidth: number = 639;

/**
 * determines if the user is on an actual mobile device
 */
export const isMobile = (maxWidth: number = mobileMaxWidth) => {
  return hasCoarsePointer() && hasMultipleTouchPoints() && hasMobileWidth(maxWidth) && hasTouchEvents();
};
/**
 * determines if the user is on mobile resolution
 */
export const isOnMobileResolution = (maxWidth: number = mobileMaxWidth) => hasMobileWidth(maxWidth);

export const loadMobile = () => {
  $$(".js-is-mobile", (el) => el.classList.remove("d-none"));
};

export const loadMobileResolutionEvents = () => {
  $$(".js-mobile-value", (el) => {
    const mobileValue = el.dataset.mobileValue;
    if (!(el instanceof HTMLInputElement) || !mobileValue) {
      console.error("element was not a HTMLInputElement or has no data-mobileValue", el);
      return;
    }
    const inputElement = <HTMLInputElement>el;
    inputElement.value = mobileValue!;
  });
};
window.addEventListener("load", function () {
  if (isMobile()) loadMobile();
  if (isOnMobileResolution()) loadMobileResolutionEvents();
});
