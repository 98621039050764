import $$ from "../utils/double-dollar.ts";

export default class EditableField {
  constructor(private el: Element) {
    const startEditElement = this.el.querySelector(".js-on-click-trigger-edit-mode");
    startEditElement?.addEventListener("click", this.startEditMode.bind(this));
    const stopEditElement = this.el.querySelector(".js-on-click-end-edit-mode");
    stopEditElement?.addEventListener("click", this.stopEditMode.bind(this));
  }

  public static init(): void {
    $$(".js-editable-field", (el) => new EditableField(el));
  }

  private startEditMode(e: Event): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.el.classList.add("esc-is-edit-mode");
  }

  private stopEditMode(e: Event): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.el.classList.remove("esc-is-edit-mode");
  }
}

EditableField.init();
