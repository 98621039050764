import DividedAutocompleteItem from "../models/divided-autocomplete-item";
import SearchSuggestion from "../models/search-suggestion";
import autocompleteWithDividers, { clearAutocomplete } from "../utils/autocomplete-utils";
import { isElementHidden } from "../utils/is-element-hidden";

export default class SearchAutoComplete {
  private static timeout: ReturnType<typeof setTimeout>;
  public constructor(
    private searchElement: HTMLInputElement,
    private isMobile?: boolean,
  ) {
    searchElement.addEventListener("input", this.handleEvent.bind(this));
    searchElement.addEventListener("focus", this.handleEvent.bind(this));
  }
  static init(elementId: string, isMobile?: boolean) {
    const element = document.getElementById(elementId);
    if (element instanceof HTMLInputElement) new SearchAutoComplete(element, isMobile);
  }

  handleEvent(e: Event): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.debounced();
  }

  private debounced(): void {
    const debouncedSearch = this.debounce(async () => await this.getSearchSuggestions(), 500);
    debouncedSearch();
  }

  private debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
    return function (...args: Parameters<T>) {
      clearTimeout(SearchAutoComplete.timeout);
      SearchAutoComplete.timeout = setTimeout(() => func.apply(args), wait);
    };
  }

  private async getSearchSuggestions() {
    try {
      if (this.searchElement.value.length < 2) {
        return;
      }

      const data = await fetch(`/api/SearchSuggestion?query=${this.searchElement.value}`);
      const json = await data.json();

      this.processSearchSuggestions(json);
    } catch (e) {
      console.error(e);
    }
  }

  private processSearchSuggestions(data: any) {
    let dictionary: { [key: string]: DividedAutocompleteItem[] } = {};
    // on mobile the suggestions need to exceed the offcanvas height thus cannot be inside an offcanvas
    const alternativeParentElement = this.isMobile ? document.getElementById("offcanvasSearch") : null;
    const translations = data.translations;
    // 1st TEXT suggestions
    dictionary[translations.textSuggestions] = [];
    const textSuggestions = data.textSuggestions as SearchSuggestion[];
    for (const textSuggestion of textSuggestions) {
      let iconClass = textSuggestion.isFromHistory ? "material-symbols--history-rounded" : null;
      dictionary[translations.textSuggestions].push({
        label: textSuggestion.text,
        value: "",
        iconClass: iconClass,
      });
    }
    // 2nd BRAND suggestions
    dictionary[translations.brandSuggestions] = [];
    const brandSuggestions = data.brandSuggestions as any[];
    for (const brand of brandSuggestions) {
      dictionary[translations.brandSuggestions].push({ label: brand.text, value: brand.uri, iconClass: null });
    }

    if (
      dictionary[translations.textSuggestions].length === 0 &&
      dictionary[translations.brandSuggestions].length === 0
    ) {
      clearAutocomplete(this.searchElement, alternativeParentElement ?? this.searchElement);
      return;
    }

    const autoCompleteOptions = {
      source: dictionary,
      onSelectItem: (value: string, label: string, _element: any) => {
        if (value) {
          location.href = `${window.location.origin}/${value}`;
          return;
        }
        this.setSearchTextAndSubmit(label);
      },
      highlightClass: "search-accent-text",
      threshold: 0,
      maximumItems: 10,
    };

    autocompleteWithDividers(this.searchElement, autoCompleteOptions, alternativeParentElement);
    this.searchElement.dispatchEvent(new Event("click"));
  }

  /**
   * Instead of using URI, set search text on form and submit form
   * such that all the search form logic can be followed
   * (i.e. get all brands that match a search term, even if a brand
   * has already been filtered on).
   * @param searchText
   */
  setSearchTextAndSubmit(searchText: string) {
    this.searchElement.value = searchText;
    this.searchElement.form!.requestSubmit();
  }
}

const mobileSearchForm = document.getElementById("product-search--mobile-form");
if (isElementHidden(mobileSearchForm)) {
  SearchAutoComplete.init("product-search--search-term");
} else {
  SearchAutoComplete.init("product-search--mobile-search-term", true);
}
