import { Toast } from "bootstrap";
import "./utils/array-extensions";

type ToastMessageType = "success" | "info" | "warning" | "error";

type BsContextualClass = "primary" | "secondary" | "success" | "info" | "warning" | "danger" | "light" | "dark";

export function getToastMessageType(value: string | null): ToastMessageType {
  if (value === "success" || value === "info" || value === "warning" || value === "error")
    return value as ToastMessageType;
  else if (!value) return "success" as ToastMessageType;
  else throw new Error(`Given value is not a ToastMessageType: ${value}`);
}

function getContextualClass(type: ToastMessageType): BsContextualClass {
  switch (type) {
    case "success":
      return "success";
    case "info":
      return "info";
    case "warning":
      return "warning";
    case "error":
      return "danger";
    default:
      return "info";
  }
}

export default class Toaster {
  private constructor() {
    /* no-op */
  }

  static success(body: string, title?: string) {
    Toaster.show(body, title, "success");
  }
  static info(body: string, title?: string) {
    Toaster.show(body, title, "info");
  }
  static warning(body: string, title?: string) {
    Toaster.show(body, title, "warning");
  }
  static error(body: string, title?: string) {
    Toaster.show(body, title, "error", { autohide: false });
  }

  static show(body: string, title?: string, type: ToastMessageType = "info", config?: Partial<Toast.Options>) {
    if (!body && !title) {
      return undefined;
    }
    const toastContainer = document.querySelector(".toast-container") ?? Toaster.createToastContainerElement();
    const toastHtml = Toaster.createToastHtml(body, title, type);
    const toastElement = Toaster.createElementFromHTML(toastHtml);
    if (!toastElement) {
      return undefined;
    }
    toastContainer.appendChild(toastElement);
    if (toastElement) {
      const toast = Toast.getOrCreateInstance(toastElement, config);
      toast.show();
      return toast;
    }
    return undefined;
  }

  private static createToastContainerElement(): HTMLElement {
    const toastContainer = document.createElement("div");
    toastContainer.classList.add("toast-container", "position-fixed", "bottom-0", "end-0", "p-3");
    document.body.append(toastContainer);
    return toastContainer;
  }

  private static createToastHtml(body: string, title?: string, type: ToastMessageType = "info"): string {
    const contextualClass = getContextualClass(type);
    if (!!title)
      return `
      <div class="toast text-bg-${contextualClass}" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">${title}</strong>
          <button type="button" class="material-symbols--close-outline" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          ${body}
        </div>
      </div>
    `;

    return `
    <div class="toast text-bg-${contextualClass}" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">
          ${body}
        </div>
        <button type="button" class="material-symbols--close-outline bg-light me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>
    `;
  }

  private static createElementFromHTML(html: string) {
    const template = document.createElement("template");
    template.innerHTML = html.trim();
    const children = template.content.children;
    const array: Element[] = [].slice.call(children);
    return array.first();
  }
}
