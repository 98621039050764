import $$ from "../utils/double-dollar.ts";

export default class CustomerProductCode {
  constructor(el: HTMLElement) {
    el.addEventListener("click", () => {
      el.classList.remove("is-valid");
      el.classList.remove("is-invalid");
    });
  }
  static init() {
    $$(".js-customer-product-code", (el) => new CustomerProductCode(el));
  }
}

CustomerProductCode.init();
