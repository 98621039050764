// core version + navigation, pagination modules:
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
//import { PaginationOptions } from "swiper/types";
// import Swiper and modules styles

export default class CustomSwiper {
  static getBreakpointValue(breakpoint: string) {
    return parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(`--swiper-breakpoint-${breakpoint}`).trim(),
    );
  }

  static init() {
    CustomSwiper.initializeSwiper(".swiper");
  }

  static initializeSwiper(selector: string) {
    new Swiper(selector, {
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: ".esc-swiper-button-next",
        prevEl: ".esc-swiper-button-prev",
      },
      watchOverflow: true,
      pagination: {
        el: ".esc-swiper-pagination",
        clickable: true,
        //dynamicBullets: true,
        //dynamicMainBullets: 6
      },
      slidesPerView: 1.2,
      slidesPerGroup: 1,
      spaceBetween: 16,
      breakpoints: {
        [CustomSwiper.getBreakpointValue("sm")]: {
          slidesPerView: 2.2,
          slidesPerGroup: 2,
          spaceBetween: 16,
        },
        [CustomSwiper.getBreakpointValue("md")]: {
          slidesPerView: 3.2,
          slidesPerGroup: 2,
          spaceBetween: 16,
        },
        [CustomSwiper.getBreakpointValue("lg")]: {
          slidesPerView: 4.3,
          slidesPerGroup: 4,
          spaceBetween: 16,
        },
      },
      on: {
        init(swiper: Swiper) {
          const pages = swiper.el.querySelectorAll(".swiper-pagination-bullet");

          if (pages && pages.length <= 1) {
            var container = swiper.el.querySelector(".esc-swiper-button-container");
            container?.classList.add("d-none");
          }
          swiper.el.classList.remove("invisible");
        },
      },
    });
  }
}
