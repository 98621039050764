/*
 * See CustomImageTypeExtension.cs
 */
export enum CustomImageType {
  Original = 0,

  /// <summary>
  /// Image at 400x400.
  /// </summary>
  ProductDetail,

  /// <summary>
  /// Image at 800x800.
  /// </summary>
  ProductLarge,

  /// <summary>
  /// Image at 160x160.
  /// </summary>
  ProductResult,

  /// <summary>
  /// Image at 80x80.
  /// </summary>
  ProductThumbnail,
}
