import $$ from "../utils/double-dollar";

export default class ProductQuantityPicker {
  private readonly quantityElement: HTMLInputElement | null;

  constructor(private el: Element) {
    this.quantityElement = this.el.querySelector(".js-quantity");

    const decrementElement = this.el.querySelector(".js-quantity-decrement");
    decrementElement?.addEventListener("click", this.decrement.bind(this));

    const incrementElement = this.el.querySelector(".js-quantity-increment");
    incrementElement?.addEventListener("click", this.increment.bind(this));

    this.quantityElement?.addEventListener("change", () => {
      if (!this.quantityElement) return;
      const newValue = this.quantityElement.valueAsNumber;
      if (newValue === undefined || newValue === null || Number.isNaN(newValue)) {
        this.quantityElement.valueAsNumber = this.quantity;
      } else {
        this.quantity = newValue;
      }
    });

    // prevent double loading
    el.classList.add("js-product-quantity-picker--loaded");
  }

  static init(element: Element | null = null) {
    $$(
      ".js-product-quantity-picker:not(.js-product-quantity-picker--loaded)",
      (el) => new ProductQuantityPicker(el),
      element,
    );
  }

  public decrement(): void {
    this.quantity = this.quantity - this.ioq;
  }

  public increment(): void {
    this.quantity = this.quantity + this.ioq;
  }

  public get quantity(): number {
    const val: string = this.quantityElement?.getAttribute("value") || "0";
    const qty: number = parseInt(val);
    return Number.isNaN(qty) ? 0 : qty;
  }

  public set quantity(value: number) {
    const currentQuantity = this.quantity;
    if (value < this.moq) {
      value = this.moq;
    } else {
      value = Math.ceil(value / this.ioq) * this.ioq;
    }

    if (this.quantityElement !== null) {
      this.quantityElement.value = value.toString();
      this.quantityElement.setAttribute("value", value.toString());
    }

    if (currentQuantity !== value) {
      this.raiseQuantityChangedEvent();
    }
  }

  public get moq(): number {
    const val: string = this.quantityElement?.getAttribute("min") || "0";
    return Math.max(1, parseInt(val));
  }

  public get ioq(): number {
    const val: string = this.quantityElement?.getAttribute("step") || "1";
    return Math.max(1, parseInt(val));
  }

  private raiseQuantityChangedEvent() {
    const event = new CustomEvent("quantity-changed", {
      bubbles: true,
      detail: {
        quantity: this.quantity,
      },
    });
    this.quantityElement?.dispatchEvent(event);
  }
}

ProductQuantityPicker.init();
