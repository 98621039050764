export default class Pager {
  public static instance: Pager;
  private currentPage: number = 1;
  private constructor(
    private elements: HTMLElement[],
    private totalPages: number = 1,
    private onChangePage: (newPage: number) => void,
  ) {
    this.elements.forEach((element: HTMLElement) => {
      const prevBtn = element.querySelector(".esc-page-prev") as HTMLButtonElement;
      prevBtn.addEventListener("click", () => this.onPrevious());
      const nextBtn = element.querySelector(".esc-page-next") as HTMLButtonElement;
      nextBtn.addEventListener("click", () => this.onNext());
    });
    this.setPaging(1, this.totalPages);
  }

  setPaging(currentPage: number, totalPages: number) {
    if (totalPages === 0) totalPages = 1;
    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.setPageDisplay();
  }

  setTotalpages(totalPages: number) {
    if (totalPages === 0) totalPages = 1;
    this.totalPages = totalPages;
    this.setPageDisplay();
  }

  setPageDisplay() {
    this.elements.forEach((element: HTMLElement) => {
      const pageDisplay = element.querySelector(".js-page-display") as HTMLElement;
      pageDisplay.innerText = `${this.currentPage}/${this.totalPages}`;
    });
  }

  onPrevious() {
    if (this.currentPage - 1 < 1) return;

    this.setPaging(this.currentPage - 1, this.totalPages);
    this.onChangePage(this.currentPage);
  }

  onNext() {
    if (this.currentPage + 1 > this.totalPages) return;

    this.setPaging(this.currentPage + 1, this.totalPages);
    this.onChangePage(this.currentPage);
  }

  public static init(totalPages: number, onChangePage: (newPage: number) => void): Pager {
    if (!Pager.instance) {
      const elements = Array.from(document.querySelectorAll(".js-pager")) as HTMLElement[];
      if (elements.length > 0) {
        Pager.instance = new Pager(elements, totalPages, onChangePage);
      }
    }
    return Pager.instance;
  }
}
