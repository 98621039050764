export default class QuickOrderModal {
  private readonly inputElement: HTMLInputElement | null;
  private readonly buttonElement: HTMLButtonElement | null;

  constructor(private el: Element) {
    this.inputElement = this.el.querySelector('[name="code"]') as HTMLInputElement;
    this.buttonElement = this.el.querySelector("button[data-quick-order-submit]") as HTMLButtonElement;
    if (this.inputElement && this.buttonElement) {
      this.inputElement.addEventListener("input", this.toggleButtonElement.bind(this));
      this.toggleButtonElement();
      el.classList.add("js-quick-order-modal--loaded");
    } else {
      console.error("Required elements not found");
    }
  }

  toggleButtonElement() {
    const disabled = !this.inputElement || !this.inputElement.value.trim();

    if (disabled) {
      this.buttonElement!.setAttribute("disabled", "true");
      this.buttonElement!.disabled = true;
    } else {
      this.buttonElement!.removeAttribute("disabled");
    }
  }

  static init(elt: HTMLElement) {
    new QuickOrderModal(elt); // singular instance
  }
}
