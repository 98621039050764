export default class DatoCmsImage {
  public static init() {
    const images = document.querySelectorAll(".js-datocms-image");
    if (images.length > 0) {
      const observer = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            const img = entry.target as HTMLImageElement;
            const dataSrc = img.getAttribute("data-src");
            if (!dataSrc) {
              return;
            }
            img.src = dataSrc;
            img.onload = function () {
              img.removeAttribute("data-src");
            };
            observer.unobserve(img);
          }
        });
      });

      images.forEach(function (image) {
        observer.observe(image);
      });
    }
  }
}

document.addEventListener("DOMContentLoaded", function () {
  DatoCmsImage.init();
});
