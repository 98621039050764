/**
 * Swaps a main image src to whatever secondary image is selected
 * The source of the main image thus becomes that of the selected secondary image
 *
 * The secondary image that was clicked gets the "active" class and other secondary images become non-active
 */
export default class SelectedImageDisplay {
  readonly activeClass = "active";
  modelIdentifier: string = "";
  modelItemIdentifier: string = "";

  public constructor(
    private mainImageElement: HTMLImageElement,
    private viewer360Element: HTMLElement | null,
    private secondaryImageCollection: HTMLImageElement[],
  ) {
    this.secondaryImageCollection.forEach((img) => {
      img.addEventListener("click", this.onImageClick);
    });
  }

  static init(imageElementId: string, viewer360ElementId: string, secondaryImagesSelector: string) {
    const imgElement = document.getElementById(imageElementId);
    const viewer360Element = document.getElementById(viewer360ElementId);

    const secondaryElements = document.querySelectorAll(secondaryImagesSelector);
    const secondaryImgElements: HTMLImageElement[] = [...secondaryElements].filter(
      (element): element is HTMLImageElement => element instanceof HTMLImageElement,
    );

    if (imgElement instanceof HTMLImageElement && secondaryImgElements)
      new SelectedImageDisplay(imgElement, viewer360Element, secondaryImgElements);
  }

  onImageClick = (event: MouseEvent) => {
    if (event.target instanceof HTMLImageElement) {
      if (event.target.dataset["switchTo-360"] === "true") {
        addClass(this.viewer360Element, this.activeClass);
        removeClass(this.mainImageElement, this.activeClass);
      } else {
        removeClass(this.viewer360Element, this.activeClass);
        addClass(this.mainImageElement, this.activeClass);
        this.mainImageElement.src = event.target.dataset.detailImgSrc ?? event.target.src;
      }
      this.secondaryImageCollection.forEach((secondaryImg: HTMLImageElement) => {
        secondaryImg.classList.remove(this.activeClass);
      });
      event.target.classList.add(this.activeClass);
    }
  };
}

function addClass(element: Element | null, className: string) {
  if (!element) return;
  if (!element.classList.contains(className)) element.classList.add(className);
}

function removeClass(element: Element | null, className: string) {
  if (!element) return;
  if (element.classList.contains(className)) element.classList.remove(className);
}

SelectedImageDisplay.init("esc-product-detail-image", "esc-360-viewer", ".js-image-gallery-image-switcher");
