import { Modal as BsModal } from "bootstrap";

export default class Modal {
  private constructor() {
    /* no-op */
  }

  static dismiss() {
    const modalElement = document.querySelector(".modal");
    if (modalElement) {
      const modal = BsModal.getOrCreateInstance(modalElement);
      modal.hide();
    }
  }
}
