// @ts-ignore
if (!document.body.attributes.__htmx_antiforgery) {
  document.addEventListener("htmx:configRequest", (evt) => {
    // @ts-ignore
    const detail = evt.detail;
    let httpVerb = detail.verb.toUpperCase();
    if (httpVerb === "GET") return;
    // @ts-ignore
    let antiForgery = htmx.config.antiForgery;
    if (antiForgery) {
      // already specified on form, short circuit
      if (detail.parameters[antiForgery.formFieldName]) return;

      if (antiForgery.headerName) {
        detail.headers[antiForgery.headerName] = antiForgery.requestToken;
      } else {
        detail.parameters[antiForgery.formFieldName] = antiForgery.requestToken;
      }
    }
  });
  document.addEventListener("htmx:afterOnLoad", (evt) => {
    // @ts-ignore
    const detail = evt.detail;
    if (detail.boosted) {
      const parser = new DOMParser();
      const html = parser.parseFromString(detail.xhr.responseText, "text/html");
      const selector = "meta[name=htmx-config]";
      const config = html.querySelector(selector);
      if (config) {
        const current = document.querySelector(selector);
        // only change the anti-forgery token
        const key = "antiForgery";
        // @ts-ignore
        htmx.config[key] = JSON.parse(config.attributes["content"].value)[key];
        // update DOM, probably not necessary, but for sanity's sake
        // @ts-ignore
        current.replaceWith(config);
      }
    }
  });
  // @ts-ignore
  document.body.attributes.__htmx_antiforgery = true;
}
