import BarcodeScanner from "./barcode-scanner";

/*
 * Searches directly on the barcode and redirects to the detail page if found
 *
 */
const barcodePickerElement = document.body.querySelector(".js-search-scan-barcode-picker") as HTMLElement;
if (barcodePickerElement && barcodePickerElement.dataset) {
  const searchProductUrl = barcodePickerElement.dataset.searchProductUrl as string;

  const onBarcodeScan = async (barcode: string) => {
    window.location.href = `${searchProductUrl}?q=${encodeURI(barcode)}`;
  };

  BarcodeScanner.init(".js-search-scan-barcode-picker", ".js-toggle-barcode-list", onBarcodeScan);

  // re-initialize once the tab is reselected
  const barcodeScanTab = document.body.querySelector(".js-toggle-barcode-scan") as HTMLElement | null;
  barcodeScanTab?.addEventListener("click", () => {
    BarcodeScanner.init(".js-search-scan-barcode-picker", ".js-toggle-barcode-list", onBarcodeScan);
  });
}
