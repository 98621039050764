import { CustomImageType } from "./custom-image-type";

/*
 * See CustomImageTypeExtension.cs
 */
export default class CustomImageTypeExtension {
  public static readonly Detail = "detail";
  public static readonly Result = "result";
  public static readonly Large = "large";
  public static readonly Thumb = "thumb";
  public static readonly Orbitvu = "orbitvu";
  private static readonly Types = [this.Detail, this.Result, this.Large, this.Thumb];
  private static readonly ImagePlaceholerBasePath = "/img/placeholder/";
  static getPlaceholderUrl(imageType: CustomImageType): string {
    return this.ImagePlaceholerBasePath + this.getImageFilePlaceholderName(imageType);
  }
  /**
   * Removes any suffix present on the given url and adds the suffix that matches the given context
   * @param url url with or without a suffix
   * @param context type of desired suffix
   * @returns url with new suffix
   */
  static changeImageFileSuffix(url: string, context: CustomImageType) {
    const lastIndexOfPeriod = url.lastIndexOf(".");
    const fileSuffix = url.slice(lastIndexOfPeriod);
    let urlWithoutFileSuffix = url.slice(0, lastIndexOfPeriod);

    // remove any current context suffix
    this.Types.forEach((type) => {
      if (urlWithoutFileSuffix.endsWith(type)) urlWithoutFileSuffix = urlWithoutFileSuffix.slice(0, -type.length);
    });
    // get the new context suffix
    const newSuffix = this.getImageFileSuffix(context);
    // combine the url, new context suffix and file suffix
    return urlWithoutFileSuffix + newSuffix + fileSuffix;
  }

  static isSameSrcRegardlessOfSuffix(firstUrl: string, secondUrl: string): boolean {
    if (!firstUrl || !secondUrl) return false;
    if (!firstUrl.includes(".") || !secondUrl.includes(".")) return false;

    let firstUrlWithoutFileSuffix = firstUrl.slice(0, firstUrl.lastIndexOf("."));
    let secondUrlWithoutFileSuffix = secondUrl.slice(0, secondUrl.lastIndexOf("."));
    this.Types.forEach((type) => {
      if (firstUrlWithoutFileSuffix.endsWith(type))
        firstUrlWithoutFileSuffix = firstUrlWithoutFileSuffix.slice(0, -type.length);
      if (secondUrlWithoutFileSuffix.endsWith(type))
        secondUrlWithoutFileSuffix = secondUrlWithoutFileSuffix.slice(0, -type.length);
    });
    return firstUrlWithoutFileSuffix === secondUrlWithoutFileSuffix;
  }

  static isPlaceholderUrl(src: string) {
    return src.includes(this.ImagePlaceholerBasePath);
  }

  static getImageFileSuffix(imageType: CustomImageType): string {
    switch (imageType) {
      case CustomImageType.ProductDetail:
        return CustomImageTypeExtension.Detail;
      case CustomImageType.ProductResult:
        return CustomImageTypeExtension.Result;
      case CustomImageType.ProductLarge:
        return CustomImageTypeExtension.Large;
      case CustomImageType.ProductThumbnail:
        return CustomImageTypeExtension.Thumb;
      default:
        return "";
    }
  }

  static getImageFilePlaceholderName(imageType: CustomImageType): string {
    switch (imageType) {
      case CustomImageType.ProductDetail:
        return "Size=400x400.svg";
      case CustomImageType.ProductResult:
        return "Size=160x160.svg";
      case CustomImageType.ProductLarge:
        return "Size=800x800.svg";
      case CustomImageType.ProductThumbnail:
        return "Size=80x80.svg";
      default:
        return "";
    }
  }
}
