import {
  Camera,
  CameraSwitchControl,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  RectangularViewfinder,
  RectangularViewfinderLineStyle,
  RectangularViewfinderStyle,
  configure,
} from "scandit-web-datacapture-core";
import {
  Barcode,
  BarcodeCapture,
  BarcodeCaptureOverlay,
  BarcodeCaptureOverlayStyle,
  BarcodeCaptureSession,
  BarcodeCaptureSettings,
  Symbology,
  SymbologySettings,
  barcodeCaptureLoader,
} from "scandit-web-datacapture-barcode";

declare global {
  interface Window {
    continueScanning: () => Promise<void>;
  }
}

export default class BarcodeScanner {
  private static instance: BarcodeScanner;
  private barcodeCapture: BarcodeCapture | undefined;
  private barcodeCaptureOverlay: BarcodeCaptureOverlay | undefined;
  private viewfinder: RectangularViewfinder | undefined;
  private camera: Camera | undefined;
  private context: DataCaptureContext | undefined;
  private view: DataCaptureView | undefined | null;
  private readonly barcodePrefix: string = "91";
  private constructor(
    private containerElement: HTMLElement,
    private btnBarcodeList: HTMLElement,
    private onScan: (barcode: string) => void,
  ) {
    const scanditLicenseKey = this.containerElement.dataset.licenseKey as string;
    this.btnBarcodeList.addEventListener("click", () => this.stop());
    this.run(scanditLicenseKey);
  }

  async stop(): Promise<void> {
    if (this.barcodeCapture) {
      await this.barcodeCapture.setEnabled(false);
    }
    if (this.camera) {
      await this.camera.switchToDesiredState(FrameSourceState.Off);
    }
    if (this.view) {
      this.view = null;
    }
  }

  public static isRunning(): boolean {
    return !!this.instance.view;
  }

  async run(scanditLicenseKey: string): Promise<void> {
    this.view = new DataCaptureView();
    this.view.connectToElement(this.containerElement);
    this.view.showProgressBar();
    await configure({
      licenseKey: scanditLicenseKey,
      libraryLocation: new URL(
        "https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/",
      ).toString(),
      moduleLoaders: [barcodeCaptureLoader()],
    });
    this.view.setProgressBarPercentage(null);
    this.view.setProgressBarMessage("Accessing Camera...");

    // Create the data capture context.
    this.context = await DataCaptureContext.create();
    // To visualize the ongoing barcode capturing process on screen, attach the data capture view that renders the
    // camera preview. The view must be connected to the data capture context.
    await this.view.setContext(this.context);

    // Try to use the world-facing (back) camera and set it as the frame source of the context. The camera is off by
    // default and must be turned on to start streaming frames to the data capture context for recognition.
    this.camera = Camera.default;
    const cameraSettings = BarcodeCapture.recommendedCameraSettings;
    await this.camera.applySettings(cameraSettings);
    await this.context.setFrameSource(this.camera);

    // The barcode capturing process is configured through barcode capture settings,
    // they are then applied to the barcode capture instance that manages barcode recognition.
    const settings: BarcodeCaptureSettings = new BarcodeCaptureSettings();

    // The settings instance initially has all types of barcodes (symbologies) disabled. For the purpose of this
    // sample we enable a very generous set of symbologies. In your own app ensure that you only enable the
    // symbologies that your app requires as every additional enabled symbology has an impact on processing times.
    settings.enableSymbologies([
      Symbology.DataMatrix, // main symbology of SandersParts
      Symbology.EAN13UPCA,
      Symbology.EAN8,
      Symbology.UPCE,
      Symbology.QR,
      Symbology.Code39,
      Symbology.Code128,
      Symbology.InterleavedTwoOfFive,
    ]);

    // Some linear/1D barcode symbologies allow you to encode variable-length data. By default, the Scandit
    // Data Capture SDK only scans barcodes in a certain length range. If your application requires scanning of one
    // of these symbologies, and the length is falling outside the default range, you may need to adjust the "active
    // symbol counts" for this symbology. This is shown in the following few lines of code for one of the
    // variable-length symbologies.
    const symbologySettings: SymbologySettings = settings.settingsForSymbology(Symbology.Code39);
    symbologySettings.activeSymbolCounts = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

    // Create a new barcode capture mode with the settings from above.
    this.barcodeCapture = await BarcodeCapture.forContext(this.context, settings);
    // Disable the barcode capture mode until the camera is accessed.
    await this.barcodeCapture.setEnabled(false);

    // Add a control to be able to switch cameras.
    this.view.addControl(new CameraSwitchControl());

    // Add a barcode capture overlay to the data capture view to render the location of captured barcodes on top of
    // the video preview. This is optional, but recommended for better visual feedback.
    const barcodeCaptureOverlay: BarcodeCaptureOverlay = await BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
      this.barcodeCapture,
      this.view,
      BarcodeCaptureOverlayStyle.Frame,
    );

    // Register a listener to get informed whenever a new barcode got recognized.
    this.barcodeCapture.addListener({
      didScan: async (_: BarcodeCapture, session: BarcodeCaptureSession) => {
        await this.barcodeCapture!.setEnabled(false);
        // Hide the viewfinder.
        await barcodeCaptureOverlay.setViewfinder(null);
        const barcode: Barcode | null = session.newlyRecognizedBarcode;
        if (!barcode) {
          return;
        }
        //temp remove the prefix
        let foundCode = barcode.data;
        if (foundCode?.startsWith(this.barcodePrefix)) {
          foundCode = foundCode.substring(this.barcodePrefix.length);
        }
        this.onScan(foundCode ?? "");
        setTimeout(async () => {
          await this.barcodeCapture!.setEnabled(true);
        }, 1500);
      },
    });

    this.viewfinder = new RectangularViewfinder(
      RectangularViewfinderStyle.Square,
      RectangularViewfinderLineStyle.Light,
    );
    await barcodeCaptureOverlay.setViewfinder(this.viewfinder);

    // Switch the camera on to start streaming frames.
    // The camera is started asynchronously and will take some time to completely turn on.
    await this.camera.switchToDesiredState(FrameSourceState.On);
    await this.barcodeCapture.setEnabled(true);
    this.view.hideProgressBar();
  }

  static init(
    barcodePickerContainerIdentifier: string,
    btnBarcodeListIdentifier: string,
    onScan: (barcode: string) => void,
  ): BarcodeScanner | null {
    //ensure reset
    if (BarcodeScanner.instance && BarcodeScanner.isRunning()) BarcodeScanner.instance.stop();

    const barcodePickerContainerElement = document.querySelector(
      barcodePickerContainerIdentifier,
    ) as HTMLElement | null;
    const btnBarcodeList = document.querySelector(btnBarcodeListIdentifier) as HTMLElement | null;
    if (btnBarcodeList && barcodePickerContainerElement) {
      this.instance = new BarcodeScanner(barcodePickerContainerElement, btnBarcodeList, onScan);
      return this.instance;
    }
    return null;
  }

  async continueScanning(): Promise<void> {
    for (const r of document.querySelectorAll(".result")!) {
      r.querySelector("button")?.removeEventListener("click", this.continueScanning);
      r.remove();
    }
    await this.barcodeCapture!.setEnabled(true);
    await this.barcodeCaptureOverlay!.setViewfinder(this.viewfinder!);
  }
  showResult(result: string): void {
    const resultElement = document.createElement("div");
    resultElement.className = "result";

    const paragraph = document.createElement("p");
    paragraph.classList.add("result-text");

    const button = document.createElement("button");
    button.textContent = "OK";
    button.addEventListener("click", this.continueScanning, { once: true });

    resultElement.append(paragraph, button);
    resultElement.querySelector(".result-text")!.textContent = result;
    document.body.append(resultElement);
  }
}
