import Toaster from "../toaster";
import DataLayerHelper from "../utils/data-layer-helper";
import { QuickScanProductModel, QuickScanUploadData } from "../models/quick-scan-models";
import BarcodeScanner from "./barcode-scanner";
import { clear, del, entries, get, set, update } from "idb-keyval";
import Pager from "./pager";
import ProductQuantityPicker from "./product-quantity-picker";

type BarcodeEntry = [string, QuickScanProductModel];

export default class BarcodeListPage {
  barcodeScanner: BarcodeScanner | undefined | null;
  private readonly pageSize: number = 20;
  private entries: BarcodeEntry[] | undefined;
  private page: number = 1;
  private template: HTMLElement | undefined;
  private pager: Pager | undefined;

  static init(
    pageElementIdentifier: string,
    btnTogglePipScannerIdentifier: string,
    btnAddManuallyIdentifier: string,
    btnDeleteAllIdentifier: string,
    btnAddToShoppingCartIdentifier: string,
    pipElementIdentifier: string,
    btnToggleBarcodeScanIdentifier: string,
  ): BarcodeListPage | null {
    const scanListElement = document.querySelector(pageElementIdentifier) as HTMLElement | null;
    const btnTogglePipScanner = document.querySelector(btnTogglePipScannerIdentifier) as HTMLButtonElement | null;
    const btnAddManually = document.querySelector(btnAddManuallyIdentifier) as HTMLButtonElement | null;
    const btnDeleteAll = document.querySelector(btnDeleteAllIdentifier) as HTMLButtonElement | null;
    const btnAddToShoppingCart = document.querySelector(btnAddToShoppingCartIdentifier) as HTMLButtonElement | null;
    const pipElement = document.querySelector(pipElementIdentifier) as HTMLButtonElement | null;
    const btnToggleBarcodeScan = document.querySelector(btnToggleBarcodeScanIdentifier) as HTMLButtonElement | null;

    if (
      scanListElement &&
      btnTogglePipScanner &&
      btnAddManually &&
      btnDeleteAll &&
      pipElement &&
      btnToggleBarcodeScan &&
      btnAddToShoppingCart
    ) {
      const getProductUrl = scanListElement.dataset.getProductUrl;
      const addToBasketUrl = scanListElement.dataset.addToBasketUrl;
      const generalExceptionText = scanListElement.dataset.exceptionText;
      return new BarcodeListPage(
        getProductUrl!,
        addToBasketUrl!,
        generalExceptionText!,
        btnTogglePipScanner,
        btnAddManually,
        btnDeleteAll,
        btnAddToShoppingCart,
        btnToggleBarcodeScan,
        pipElement,
      );
    }
    return null;
  }

  private constructor(
    private getProductUrl: string,
    private addToBasketUrl: string,
    private generalExceptionText: string,
    private btnTogglePipScanner: HTMLButtonElement,
    private btnAddManually: HTMLButtonElement,
    private btnDeleteAll: HTMLButtonElement,
    private btnAddToShoppingCart: HTMLButtonElement,
    private btnToggleBarcodeScan: HTMLButtonElement,
    private pipElement: HTMLElement,
  ) {
    this.template = document.querySelector(".js-scanned-item-template") as HTMLElement;
    this.pipElement.style.display = "none";
    this.btnTogglePipScanner.addEventListener("click", this.onTogglePipScanner.bind(this));
    this.btnAddManually.addEventListener("click", this.onAddManually.bind(this));
    this.btnDeleteAll.addEventListener("click", this.onDeleteAll.bind(this));
    this.btnAddToShoppingCart.addEventListener("click", this.onAddToShoppingCart.bind(this));
    this.btnToggleBarcodeScan.addEventListener("click", this.onExit.bind(this));
    DataLayerHelper.trackCustomEvent("quick_scan_open");
    this.pager = Pager.init(1, this.onChangePage.bind(this));
    this.reload();
  }

  async reload() {
    this.entries = await this.getBarcodeEntries();
    this.pager!.setTotalpages(Math.ceil(this.entries.length / this.pageSize));
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    const paginatedEntries = this.entries.slice(startIndex, endIndex);
    this.renderPage(paginatedEntries);
  }

  renderPage(entries: BarcodeEntry[]) {
    this.clearPage();
    entries.forEach((entry: BarcodeEntry) => {
      const barcode = entry[0];
      const entryValue = entry[1];
      const clone = this.template!.cloneNode(true) as HTMLElement;
      clone.style.display = "";
      clone.classList.remove("d-none");
      clone.classList.remove("js-scanned-item-template");
      clone.classList.add("d-flex");
      clone.classList.add("js-scanned-item");
      clone.id = barcode;
      clone.querySelector(".js-scan-item-title")!.textContent = `${entryValue.name}`;
      clone.querySelector(".js-scan-item-productnumber")!.textContent = `${entryValue.productNumber}`;
      clone.querySelector(".js-scan-item-ean")!.textContent = `${entryValue.ean}`;
      (clone.querySelector(".js-btn-delete-line")! as HTMLElement).onclick = this.onDeleteLine.bind(this, barcode);

      const clonesQuantityPickerElement = clone.querySelector(".js-product-quantity-picker");
      clonesQuantityPickerElement?.classList.remove("js-product-quantity-picker--loaded");

      const clonesQuantityElement = clone.querySelector(".js-quantity") as HTMLInputElement;
      //clonesQuantityElement.disabled = false;
      clonesQuantityElement.value = entryValue.quantity + "";
      clonesQuantityElement.setAttribute("min", entryValue.minimumOrderQuantity + "");
      clonesQuantityElement.setAttribute("step", entryValue.incrementalOrderQuantity + "");
      //clonesQuantityElement.disabled = true;

      this.template?.parentElement!.appendChild(clone);

      ProductQuantityPicker.init(clone);
      clone.addEventListener("quantity-changed", async (event) => {
        await this.onLineQuantityChange(event as CustomEvent, barcode).catch((err) => {
          console.error(err);
          Toaster.error(this.generalExceptionText);
        });
      });
    });
  }

  async onLineQuantityChange(event: CustomEvent, barcode: string) {
    const newQuantity = event.detail.quantity;
    await update(barcode, (product: any) => {
      if (product) {
        product.quantity = newQuantity;
      }
      return product;
    });
  }

  clearPage() {
    const container = this.template!.parentElement!;
    Array.from(container.children).forEach((child) => {
      if (child !== this.template) {
        container.removeChild(child);
      }
    });
  }
  onChangePage(page: number) {
    this.page = page;
    this.reload();
  }

  onTogglePipScanner() {
    const isToggledVisible = this.toggleVisibility(this.pipElement);
    if (isToggledVisible) {
      this.barcodeScanner = BarcodeScanner.init(".js-barcode-pip", ".js-toggle-barcode-scan", this.tryToAdd.bind(this));
    } else {
      this.barcodeScanner?.stop();
      this.barcodeScanner = null;
    }
  }

  onAddManually() {
    const manualAddModalBody = this.btnAddManually.parentElement!.parentElement!.parentElement!;
    const barcode = (manualAddModalBody.querySelector(".js-manual-add-input") as HTMLInputElement | null)?.value;
    const quantity = (manualAddModalBody.querySelector(".js-quantity") as HTMLInputElement | null)?.value;
    const quantityValue = quantity ? Number(quantity) : 1;
    if (barcode) {
      this.tryToAdd(barcode, quantityValue);
    } else {
      Toaster.error(this.generalExceptionText);
    }
  }

  async tryToAdd(barcode: string, quantity: number = 1) {
    const existingProduct = await get(barcode);
    if (existingProduct) {
      Toaster.error(this.generalExceptionText);
      return;
    }

    const queryUrl = `${this.getProductUrl}?barcode=${encodeURI(barcode)}`;
    const foundProductResponse = await fetch(queryUrl);
    const foundProduct = await foundProductResponse.json();

    if (!foundProduct) {
      Toaster.error(this.generalExceptionText);
      return;
    }

    const storableProduct: QuickScanProductModel = {
      productNumber: foundProduct.productNumber,
      ean: foundProduct.ean ?? "",
      incrementalOrderQuantity: foundProduct.incrementalOrderQuantity,
      minimumOrderQuantity: foundProduct.minimumOrderQuantity,
      name: foundProduct.internalName,
      unitOfMeasureValue: "",
      customerProductNumber: "",
      id: foundProduct.id,
      quantity: quantity > foundProduct.minimumOrderQuantity ? quantity : foundProduct.minimumOrderQuantity,
      barcode: barcode,
    };

    set(barcode, storableProduct)
      .then(() => {
        this.reload();
      })
      .catch((err) => {
        console.error(err);
        Toaster.error(this.generalExceptionText);
      });
  }

  onDeleteAll() {
    clear()
      .then(() => {
        this.reload();
      })
      .catch((err) => {
        console.error("Failed to clear entries:", err);
        Toaster.error(this.generalExceptionText);
      });
  }

  async onDeleteLine(barcode: string) {
    try {
      await del(barcode);
      this.reload();
    } catch (err) {
      console.error("Failed to delete product:", err);
      Toaster.error(this.generalExceptionText);
    }
  }

  async onAddToShoppingCart() {
    if (!this.entries) return;

    const uploadDataList: QuickScanUploadData[] = [];
    for (let entry of this.entries!) {
      uploadDataList.push({
        barcode: entry[1].barcode,
        quantity: entry[1].quantity,
      } as QuickScanUploadData);
    }
    const response = await fetch(this.addToBasketUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uploadDataList: uploadDataList }),
    });
    DataLayerHelper.trackCustomEvent("quick_scan_added_to_basket");
    if (response.ok) {
      var successResponse = await response.text();
      Toaster.success(successResponse);
      this.onDeleteAll();
    } else {
      var errorResponse = await response.text();
      Toaster.error(errorResponse);
    }
  }

  onExit() {
    this.barcodeScanner?.stop();
    this.barcodeScanner = null;
    this.pipElement.style.display = "none";
  }

  toggleVisibility(element: HTMLElement): boolean {
    if (element.style.display === "none") {
      element.style.display = "block";
      return true;
    }
    element.style.display = "none";
    return false;
  }

  async getBarcodeEntries(): Promise<BarcodeEntry[]> {
    try {
      const allEntries: BarcodeEntry[] = await entries();
      return allEntries;
    } catch (err) {
      console.error("Failed to retrieve entries:", err);
      Toaster.error(this.generalExceptionText);
      return [];
    }
  }
}
BarcodeListPage.init(
  ".js-barcode-list-page",
  ".js-barcode-pip-toggle",
  ".js-add-item-manually",
  ".js-delete-all-lines",
  ".js-barcode-shopping-cart",
  ".js-barcode-pip",
  ".js-toggle-barcode-scan",
);
