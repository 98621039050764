import $$ from "../utils/double-dollar.ts";

export class FileUpload {
  private readonly file: HTMLInputElement;
  private readonly text: HTMLInputElement;
  private readonly btn: HTMLButtonElement;

  constructor(private el: HTMLElement) {
    this.file = this.el.querySelector(".js-file-upload--input-file") as HTMLInputElement;
    this.text = this.el.querySelector(".js-file-upload--input-text") as HTMLInputElement;
    this.btn = this.el.querySelector(".js-file-upload--button") as HTMLButtonElement;
    this.text.addEventListener("click", this.openFilePicker.bind(this));
    if (this.btn.type === "button") {
      this.btn.disabled = false;
      this.btn.addEventListener("click", this.openFilePicker.bind(this));
    }
    this.file.addEventListener("change", this.onChangeFile.bind(this));
  }

  openFilePicker() {
    this.file.click();
  }

  private onChangeFile(): void {
    const fileName = this.file.value.replace(/^.*[\\\/]/, "") ?? "";
    this.text.value = fileName;
    if (this.btn.type === "submit") {
      this.btn.disabled = fileName === null || /^\s*$/.test(fileName);
    }
  }
}

$$(".js-file-upload", (el) => new FileUpload(el));
