// import { createApp } from 'vue'
// import App from './App.vue'
import "./htmx-setup";
import "./htmx-antiforgery";
import "./client-validation";
import "./script/components/bootstrap-components-init.ts";
import "./script/components/no-auto-submit-on-enter.ts";
import "./script/components/file-upload.ts";
import "./script/components/quote-file-upload.ts";
import "./script/components/stop-propagation.ts";
import "./script/components/scroll-into-view";
import DatoCmsImage from "./script/components/datocms-image";
import ProductQuantityPicker from "./script/components/product-quantity-picker.ts";
import EditableField from "./script/components/editable-field.ts";
import Submit from "./script/components/submit.ts";
import "./script/components/stop-propagation.ts";
import Toaster, { getToastMessageType } from "./script/toaster.ts";
import Modal from "./script/components/modal.ts";
import "./script/pages/checkout-details.ts";
import "./script/components/scroll-into-view";
import "./script/utils/is-mobile.ts";
import QuickOrderModal from "./script/components/quick-order-modal.ts";
import "./script/components/quote-file-upload.ts";
import "./script/components/search-auto-complete.ts";
import MobileSearch from "./script/components/mobile-search.ts";
import { isElementHidden } from "./script/utils/is-element-hidden.ts";
import "./script/components/barcode-direct-search.ts";
import "./script/components/barcode-list-page.ts";
import NatchGtm, { EventNamesSchema } from "natch-gtm4";
import CustomSwiper from "./script/components/custom-swiper.ts";
import "./script/components/gallery.ts";
import "./script/components/selected-image-display.ts";
import CustomerProductCode from "./script/components/customer-product-code.ts";

console.log("⚡ Loading main...");
// createApp(App).mount('#app')
// Post GA4
const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
natchGtm.processPage();

document.body.addEventListener("htmx:afterRequest", function (evt) {
  // @ts-ignore
  const detail = evt.detail;
  const element = detail.elt;
  const xhr = detail.xhr as XMLHttpRequest;

  // re-init components on new element
  ProductQuantityPicker.init(element);
  DatoCmsImage.init();
  EditableField.init();
  Submit.init();
  CustomerProductCode.init();
  MobileSearch.init(
    document.getElementById("offcanvasSearch"),
    document.getElementById("product-search--mobile-brand-button"),
    document.getElementById("product-search--mobile-search-term"),
    document.getElementById("offcanvasBrand"),
  );
  const mobileSearchForm = document.getElementById("product-search--mobile-form");
  if (isElementHidden(mobileSearchForm)) {
    MobileSearch.disable("product-search--search-term");
  }

  // show toast messages if needed
  const body = decode(xhr.getResponseHeader("X-Toast-Message"));
  const title = decode(xhr.getResponseHeader("X-Toast-Title"));
  const type = xhr.getResponseHeader("X-Toast-Type");
  const toastType = getToastMessageType(type);
  Toaster.show(body, title, toastType);

  // dismiss modal if needed
  const modalDismiss = xhr.getResponseHeader("X-Modal-Dismiss");
  if (modalDismiss === "true") {
    Modal.dismiss();
  }

  // AddToFavorites GTM
  const addToFavorites = decode(xhr.getResponseHeader("X-AddToFavorites-Product"));
  if (addToFavorites) {
    const addToCartLineAmount = parseFloat(decode(xhr.getResponseHeader("X-AddToFavorites-LineAmount")));
    natchGtm.trackAddToFavorites(JSON.parse(addToFavorites), addToCartLineAmount);
  }

  // AddTocart GTM
  const addToCartProduct = decode(xhr.getResponseHeader("X-AddToCart-Product"));
  if (addToCartProduct) {
    const addToCartLineAmount = parseFloat(decode(xhr.getResponseHeader("X-AddToCart-LineAmount")));
    natchGtm.trackAddToCart(JSON.parse(addToCartProduct), addToCartLineAmount);
  }

  // RemoveFromCart GTM
  const removeFromCartProduct = decode(xhr.getResponseHeader("X-RemoveFromCart-Product"));
  if (removeFromCartProduct) {
    const removeFromCartLineAmount = parseFloat(decode(xhr.getResponseHeader("X-RemoveFromCart-LineAmount")));
    natchGtm.trackRemoveFromCart(JSON.parse(removeFromCartProduct), removeFromCartLineAmount);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  CustomSwiper.init();
});

document.body.addEventListener("htmx:afterProcessNode", (evt) => {
  let elt: HTMLElement | null;
  // @ts-ignore
  elt = evt.detail.elt;
  ProductQuantityPicker.init(elt);
  if (elt && elt?.classList.contains("js-quick-order-modal")) QuickOrderModal.init(elt);
});

function decode(encodedValue: string | null): string {
  const utf8Prefix = "utf-8''";

  if (!encodedValue) return "";
  if (encodedValue.startsWith(utf8Prefix)) {
    encodedValue = encodedValue.substring(utf8Prefix.length);
    const decodedValue = decodeURIComponent(encodedValue);
    return decodedValue;
  }
  return encodedValue;
}
