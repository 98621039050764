import { Fancybox } from "@fancyapps/ui";
import { slideType } from "@fancyapps/ui/types/Carousel/types";
import CustomImageTypeExtension from "../utils/custom-image-type-extension";
import { CustomImageType } from "../utils/custom-image-type";

/**
 * Loads a "lightbox" , gallery slideshow of images on user-click of the main image
 * if any secondary images are present they will be available in the lightbox as well
 * if 'data-gallery-360-model' & '~-item' are defined props on the main image then the gallery will also load a 360 view
 */
export default class Gallery {
  shouldShow360: boolean = false;
  modelIdentifier: string = "";
  modelItemIdentifier: string = "";

  public constructor(
    private imageElement: HTMLElement,
    private thumbnails: HTMLImageElement[],
  ) {
    this.tryInit3dModel();

    this.imageElement.addEventListener("click", this.onImageClick);

    this.thumbnails.forEach((el: HTMLImageElement) => {
      const is360img = el.src.includes(CustomImageTypeExtension.Orbitvu);
      if (is360img) this.imageElement.addEventListener("click", this.onImageClick);
    });
  }

  static init(imageElementId: string, secondaryImagesSelector: string) {
    const imgElement = document.getElementById(imageElementId);

    const secondaryElements = document.querySelectorAll(secondaryImagesSelector);
    const secondaryImgElements: HTMLImageElement[] = [...secondaryElements].filter(
      (element): element is HTMLImageElement => element instanceof HTMLImageElement,
    );

    if (imgElement instanceof HTMLElement) new Gallery(imgElement, secondaryImgElements);
  }

  tryInit3dModel() {
    this.shouldShow360 =
      this.imageElement.hasAttribute("data-gallery-360-model") &&
      !!this.imageElement.getAttribute("data-gallery-360-model");
    this.imageElement.hasAttribute("data-gallery-360-model-item") &&
      !!this.imageElement.getAttribute("data-gallery-360-model-item");

    if (this.shouldShow360) {
      this.modelIdentifier = this.imageElement.getAttribute("data-gallery-360-model")!;
      this.modelItemIdentifier = this.imageElement.getAttribute("data-gallery-360-model-item")!;
    }
  }

  onImageClick = async (event: MouseEvent) => {
    if (event.target instanceof HTMLImageElement) {
      await this.show(event.target.src);
    }
  };

  /**
   * Shows the fancybox loaded with a larger variant of the secondary images
   * if the secondary image is a placeholder it just loads a larger placeholder
   * if the larger variant of the secondary image is not available it will load a large placeholder
   * if the larger variant is available it will load this variant
   *
   * if a 360 model was specified through data attributes on the main image an extra slide with the 360 modelviewer will be shown
   *
   * @param srcOfImageToShow an image to show as first, e.g. the user clicked the 360 thumbprint first instead of the big picture
   * @returns
   */
  async show(srcOfImageToShow: string) {
    const slides: Partial<slideType>[] = [];
    let startIndex = 0;

    for (const el of this.thumbnails) {
      const isImgElement = el instanceof HTMLImageElement && el.src && el.src != "";
      if (!isImgElement) continue;
      const is360img = el.src.includes(CustomImageTypeExtension.Orbitvu);
      if (is360img) continue;

      const isPlaceholderImg = CustomImageTypeExtension.isPlaceholderUrl(el.src);
      let largeSourceUrl: string = "";
      if (isPlaceholderImg) {
        largeSourceUrl = CustomImageTypeExtension.getPlaceholderUrl(CustomImageType.ProductLarge);
        if (CustomImageTypeExtension.isSameSrcRegardlessOfSuffix(srcOfImageToShow, el.src)) {
          startIndex = slides.length;
        }
        slides.push({ src: largeSourceUrl, thumbElSrc: el.src });
      } else {
        largeSourceUrl = CustomImageTypeExtension.changeImageFileSuffix(el.src, CustomImageType.ProductLarge);
        const isValidWithExistingImg = await this.checkImageExists(largeSourceUrl);
        if (!isValidWithExistingImg)
          largeSourceUrl = CustomImageTypeExtension.getPlaceholderUrl(CustomImageType.ProductLarge);
        if (CustomImageTypeExtension.isSameSrcRegardlessOfSuffix(srcOfImageToShow, el.src)) {
          startIndex = slides.length;
        }
        slides.push({ src: largeSourceUrl, thumbElSrc: el.src });
      }
    }

    new Fancybox(slides, {
      Fullscreen: {
        autoStart: false,
      },
      startIndex: startIndex,
    });
  }

  async checkImageExists(imageUrl: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = imageUrl;
    });
  }
}

Gallery.init("esc-product-detail-image", ".js-image-gallery-thumb");
