import * as bootstrap from "bootstrap";

export default class MobileSearch {
  private constructor(
    offcanvasSearch: HTMLElement,
    offcanvasBrandTrigger: HTMLElement,
    mobileSearchBox: HTMLElement,
    offcanvasBrand: HTMLElement,
  ) {
    const offcanvasSearchInstance = new bootstrap.Offcanvas(offcanvasSearch);
    const secondOffcanvasBrandInstance = new bootstrap.Offcanvas(offcanvasBrand);

    offcanvasSearch.addEventListener("shown.bs.offcanvas", () => {
      if (mobileSearchBox instanceof HTMLInputElement) {
        mobileSearchBox.focus();
        var len = mobileSearchBox.value.length;
        mobileSearchBox.setSelectionRange(len, len);
      }
    });

    // Prevent the first offcanvas from closing when the second one is opened
    offcanvasBrandTrigger.addEventListener("click", (event) => {
      event.preventDefault();
      secondOffcanvasBrandInstance.show();
    });

    // Ensure the first offcanvas remains open when the second one is closed
    offcanvasBrand.addEventListener("hidden.bs.offcanvas", () => {
      offcanvasSearchInstance.show();
    });
  }

  static init(
    offcanvasSearch: HTMLElement | null,
    offcanvasBrandTrigger: HTMLElement | null,
    mobileSearchBox: HTMLElement | null,
    offcanvasBrand: HTMLElement | null,
  ) {
    if (offcanvasSearch && offcanvasBrandTrigger && mobileSearchBox && offcanvasBrand)
      new MobileSearch(offcanvasSearch, offcanvasBrandTrigger, mobileSearchBox, offcanvasBrand);
  }

  static disable(elementId: string) {
    const searchBarElement = document.getElementById(elementId) as HTMLInputElement;
    if (searchBarElement) {
      searchBarElement.removeAttribute("data-bs-toggle");
    }
  }
}
