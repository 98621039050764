import $$ from "../utils/double-dollar.ts";

class CheckoutDetails {
  private readonly shippingAddressSection: Element | null;
  private readonly shippingAddressSelect: HTMLSelectElement;
  private readonly shippingAddressItems: HTMLElement[];

  constructor(private el: Element) {
    // set fields:
    this.shippingAddressSection = this.el.querySelector(".js-checkout-details--shipping-address");
    this.shippingAddressSelect = this.shippingAddressSection?.querySelector(
      "select[name='SelectedShippingAddressId']",
    ) as HTMLSelectElement;

    const shippingAddressItemElements = this.el.querySelectorAll(".js-checkout-details--shipping-address--item");
    this.shippingAddressItems = [];
    shippingAddressItemElements.forEach((item) => this.shippingAddressItems.push(item as HTMLElement));

    // set initial UI state:
    this.setDeliveryAddressDropdownVisibility();
    this.setShippingAddressVisibility();

    // set event listeners:
    el.querySelectorAll("[name='TransferType']").forEach((element) => {
      element.addEventListener("change", this.setDeliveryAddressDropdownVisibility.bind(this));
    });
    this.shippingAddressSelect?.addEventListener("change", this.setShippingAddressVisibility.bind(this));
  }

  private setDeliveryAddressDropdownVisibility() {
    const checkedTransferType = this.el.querySelector("[name='TransferType']:checked") as HTMLInputElement;
    if (checkedTransferType.value === "Delivery") {
      this.shippingAddressSection?.classList.remove("d-none");
    } else {
      this.shippingAddressSection?.classList.add("d-none");
    }
  }

  private setShippingAddressVisibility() {
    this.shippingAddressItems?.forEach((item) => {
      if (item.dataset.shippingAddressId !== this.shippingAddressSelect?.value) {
        item.classList.add("d-none");
      } else {
        item.classList.remove("d-none");
      }
    });
  }
}

$$(".js-checkout-details", (el) => new CheckoutDetails(el));
