import { ValidationService } from "aspnet-client-validation";

let v = new ValidationService();

v.addProvider("mustbetrue", (_value, element, _params) => {
  return element instanceof HTMLInputElement && element.checked;
});

v.addProvider("requiredifvisible", (value, element, _params) => (element.checkVisibility() ? !!value : true));

v.bootstrap();

document.addEventListener("htmx:beforeSend", (evt: Event) => {
  let v = new ValidationService();
  const form = (evt.target as HTMLElement).closest("form");
  if (form && !v.isValid(form)) {
    evt.preventDefault();
  }
});
