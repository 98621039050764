import $$ from "../utils/double-dollar.ts";

export default class Submit {
  constructor(
    private el: Element,
    private eventName: string,
  ) {
    this.el.addEventListener(this.eventName, this.trySubmit.bind(this));
  }

  public static init() {
    $$(".js-submit-on-change", (el) => new Submit(el, "change"));
    $$(".js-submit-on-click", (el) => new Submit(el, "click"));
  }

  private trySubmit(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const fieldName = element.dataset.fieldName;
    const formId = element.dataset.formId;
    const form = formId ? (document.getElementById(formId) as HTMLFormElement) : element.closest("form");
    if (fieldName && form) {
      const value = !!element.value ? element.value : element.dataset.value;
      const field = form.elements.namedItem(fieldName) as HTMLInputElement;
      if (field && value) {
        field.value = value;
      }
    }
    const resetFields = element.dataset.resetFields;
    if (resetFields) {
      const fields = resetFields.split(",");
      fields.forEach((field) => {
        const input = form?.elements.namedItem(field) as HTMLInputElement;
        if (input) {
          input.value = "";
        }
      });
    }
    form?.submit();
  }
}

Submit.init();
